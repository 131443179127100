import React, { Component } from 'react'
import Calculator from '../img/calculator.svg'
import PoolBox from "./PoolBox";
import {toast} from "react-toastify";

class ChangeTokens extends Component {

    constructor(props) {
        super(props);
        this.state = {
            amountInput: 0,
            inputMax: 0,
        }
    }

    render() {


        let tokensInYourWallet = this.props.tokensInYourWallet;
        let changeTokensData = this.props.changeTokensData;

        let xlionPerSlionEth = window.web3.utils.fromWei(changeTokensData.xlionPerSlion.toString(), 'Ether');

        let priceTotalSlion = 25 * this.props.ourTokenPrice;
        let priceTotalXlion = this.props.ourTokenXlionPrice;

        let percentProfits = ((priceTotalXlion / priceTotalSlion) * 100) - 100;

        let xlionForChange = (
            parseFloat(window.web3.utils.fromWei((changeTokensData.maxSlionInDeposit).toString(), 'Ether'))
            *
            parseFloat(xlionPerSlionEth)
        );

        let slionDepositedEth = window.web3.utils.fromWei(changeTokensData.slionDeposited.toString(), 'Ether');
        let xlionNonAvailable = window.web3.utils.fromWei((slionDepositedEth * changeTokensData.xlionPerSlion).toString(), 'Ether');;

        let xlionAvailableForChange = xlionForChange - xlionNonAvailable;
        let slionAvailableForDeposit = xlionAvailableForChange * 25;


        let blocksRemaining = changeTokensData.lastBlockToDepositSlion - this.props.currentBlock;
        if (blocksRemaining < 0) {
            blocksRemaining = 0;
        }

        let maxTokensToDeposit = tokensInYourWallet;
        if (slionAvailableForDeposit < maxTokensToDeposit) {
            maxTokensToDeposit = slionAvailableForDeposit;
        }

        if (maxTokensToDeposit != this.state.inputMax) {
            this.setState({
                inputMax: maxTokensToDeposit
            })
        }

        let dataUserPool = this.props.getDataUserPool(3, 0);
        //dataUserPool.stakedAmount = dataUserPool.stakedAmount;
        //let stakedAmountEth = window.web3.utils.fromWei(dataUserPool.stakedAmount.toString(), 'Ether');

        let operationButton = <button
            className="btn btn-block btn-primary unlockWalletButton"
            onClick={(event => {
                event.preventDefault();
                this.props.connectWalletConnect(true);
            })}
        >
            Connect Wallet
        </button>;

        let operationButtonCollect = operationButton;


        if (this.props.account !== '0x0') {

            if (dataUserPool.approvedQty != 0) {
                operationButton =
                    <button
                        className="depositSlionButton btn btn-block btn-primary"
                        onClick={(event => {
                            event.preventDefault()
                            let amount

                            //this.state.amountInput = 1;

                            amount = this.state.amountInput;

                            console.log(amount);

                            this.props.deposit(3, 0, amount)
                        })}
                    >
                        Deposit SLION's
                    </button>;
            } else {
                operationButton = <button
                    className="btn btn-block btn-primary approveButton"
                    onClick={(event => {
                        event.preventDefault();
                        this.props.approveContract(3, 'token', this.props.ourTokenContract);
                    })}
                >
                    Approve
                </button>
            }

            if (blocksRemaining == 0 || slionAvailableForDeposit == 0) {
                operationButton = <button
                    className="depositSlionButton btn btn-block btn-primary disabled"
                >
                    Deposit SLION's
                </button>
            }


            operationButtonCollect =
                <button
                    className="claimXlionButton btn btn-block btn-primary"
                    onClick={(event => {
                        event.preventDefault()
                        let amount
                        amount = this.state.amountInput;

                        this.props.withdraw(3, 0, amount)
                    })}
                >
                    Claim XLION's!
                </button>;

            if (blocksRemaining > 0 || dataUserPool.rewardDebt == 0) {
                operationButtonCollect =
                    <button
                        className="claimXlionButton btn btn-block btn-primary disabled"
                    >
                        Claim XLION's!
                    </button>;
            }
        }

        let linkCountDown = 'https://bscscan.com/block/countdown/' + changeTokensData.lastBlockToDepositSlion;

        return (
            <div className="row rowChangeTokens">
                <div className="col-12 text-center colChangeTokens">
                    <h1 id="ChangeTokensTitle">Swap your <span className="slionName">SLION</span> for <span className="xlionName">XLION</span></h1>

                </div>
                <div className="col-12 text-center colChangeTokensAlert">
                    <p>
                        <span className="alertText">ALERT!</span> You'll get your XLIONs on the block <a target="_blank" href={linkCountDown}>{changeTokensData.lastBlockToDepositSlion}</a>
                    </p>
                    <p>
                        approximately 1 week after the start of the XLION MasterChef, to avoid fast inflation.
                    </p>
                </div>
                <div className="col-5 text-center colSlionValue">
                    <div className="colSlionValueContent">
                        <div className="slionQty">
                            <span className="slionQtyValue">25</span> <span className="slionQtySimbol">Slion</span>
                        </div>
                        <div className="slionUSD">
                            <span className="slionQtyValue">{priceTotalSlion}</span> <span className="slionQtySimbol">$</span>
                        </div>
                    </div>
                </div>

                <div className="col-2 text-center colChangeFor">
                    <img src="/xchange.svg" alt=""/>
                </div>

                <div className="col-5 text-center colXlionValue">
                    <div className="colXlionValueContent">
                        <div className="xlionQty">
                            <span className="xlionQtyValue">1</span> <span className="xlionQtySimbol">Xlion</span>
                        </div>
                        <div className="xlionUSD">
                            <span className="xlionQtyValue">{this.props.ourTokenXlionPrice}</span> <span className="xlionQtySimbol">$</span>
                        </div>
                    </div>
                </div>

                <div className="col-12 text-center colYouEarn">
                    <span className="youEarnText">You earn </span>
                    <span className="youEarnPercent">{percentProfits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %</span>
                </div>

                <div className="col-12 text-center colXlionAvailable">
                    <div className="colXlionAvailableContent">
                        <span className="colXlionAvailableQty">{slionAvailableForDeposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> <span className="xlionName">SLION</span> available for change of <span className="colXlionAvailableQtyOf">{parseFloat(window.web3.utils.fromWei((changeTokensData.maxSlionInDeposit).toString(), 'Ether')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </div>
                </div>

                <div className="col-6 text-center colSlionDeposit">
                    <div className="row">
                        <div className="col-12 colSlionDepositText">
                            You deposited <span className="colSlionDepositQty">{parseFloat(window.web3.utils.fromWei(dataUserPool.stakedAmount.toString(), 'Ether')).toFixed(7).toString().replace(/\B(?=(\d{7})+(?!\d))/g, ",")}</span> <span className="slionName">SLION</span>
                        </div>
                        <div className="col-12 colSlionDepositCointanerInput">
                            <input
                                className="tokenInput"
                                type="number"
                                value={this.state.amountInput}

                                onChange={(event => {

                                    let amount = event.target.value;

                                    if (amount > this.state.inputMax) {
                                        amount = this.state.inputMax;
                                    }

                                    if (amount < 0) {
                                        amount = 0;
                                    }

                                    this.setState({
                                        amountInput: amount
                                    })
                                })}

                                max={this.state.inputMax}
                            />
                            <div className="maxButtonContainer">
                                <a
                                    className="maxButton btn btn-primary"
                                    href=""
                                    onClick={(event => {
                                        event.preventDefault();

                                        //let maxValueEther = window.web3.utils.fromWei(tokensInYourWallet.toString(), 'Ether')

                                        let maxTokensToDeposit = tokensInYourWallet;

                                        if (slionAvailableForDeposit < maxTokensToDeposit) {
                                            maxTokensToDeposit = slionAvailableForDeposit;
                                        }

                                        this.setState({
                                            amountInput: maxTokensToDeposit
                                        })
                                    })}
                                >MAX</a>
                            </div>
                        </div>
                        <div className="col-12">
                            {parseFloat(tokensInYourWallet.toString()).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} SLION's in your wallet
                        </div>
                        <div className="col-12 colSlionDepositCointanerButton">
                            {operationButton}
                        </div>

                        <div className="col-12 colSlionDepositCointanerAlert">
                            ALERT! You will not be able to withdraw your deposited SLION. These will be burned immediately upon depositing them to make the change.
                        </div>
                    </div>
                </div>

                <div className="col-6 text-center colXlionForClaim">
                    <div className="col-12 colXlionForClaimText">
                        <span className="colXlionForClaimQty">{parseFloat(window.web3.utils.fromWei(dataUserPool.rewardDebt.toString(), 'Ether')).toFixed(7).toString().replace(/\B(?=(\d{7})+(?!\d))/g, ",")}</span> <span className="xlionName">XLION</span> for claim
                    </div>
                    <div className="col-12 colXlionForClaimCointanerButton">
                        {operationButtonCollect}
                    </div>
                    <div className="col-12 colXlionForClaimCointanerAlert">
                        {blocksRemaining.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} reamining blocks for claim your XLION's
                        <br />
                        <a target="_blank" href={linkCountDown}>See countdown</a>
                    </div>
                </div>

            </div>
        );
    }
}

export default ChangeTokens;
